// Uncomment these components as you need them
// Imported from primevue folder
import accordion from '@primevue/themes/aura/accordion';
// import autocomplete from '@primevue/themes/aura/autocomplete';
// import avatar from '@primevue/themes/aura/avatar';
// import badge from '@primevue/themes/aura/badge';
// import blockui from '@primevue/themes/aura/blockui';
// import breadcrumb from '@primevue/themes/aura/breadcrumb';
// import button from '@primevue/themes/aura/button';
// import card from '@primevue/themes/aura/card';
// import carousel from '@primevue/themes/aura/carousel';
// import cascadeselect from '@primevue/themes/aura/cascadeselect';
// import checkbox from '@primevue/themes/aura/checkbox';
// import chip from '@primevue/themes/aura/chip';
// import colorpicker from '@primevue/themes/aura/colorpicker';
// import confirmdialog from '@primevue/themes/aura/confirmdialog';
// import confirmpopup from '@primevue/themes/aura/confirmpopup';
// import contextmenu from '@primevue/themes/aura/contextmenu';
// import datatable from '@primevue/themes/aura/datatable';
// import dataview from '@primevue/themes/aura/dataview';
import datepicker from '@primevue/themes/aura/datepicker';
// import dialog from '@primevue/themes/aura/dialog';
// import divider from '@primevue/themes/aura/divider';
// import dock from '@primevue/themes/aura/dock';
// import drawer from '@primevue/themes/aura/drawer';
// import editor from '@primevue/themes/aura/editor';
// import fieldset from '@primevue/themes/aura/fieldset';
// import fileupload from '@primevue/themes/aura/fileupload';
// import floatlabel from '@primevue/themes/aura/floatlabel';
// import galleria from '@primevue/themes/aura/galleria';
// import iconfield from '@primevue/themes/aura/iconfield';
// import image from '@primevue/themes/aura/image';
// import inlinemessage from '@primevue/themes/aura/inlinemessage';
// import inplace from '@primevue/themes/aura/inplace';
// import inputchips from '@primevue/themes/aura/inputchips';
// import inputgroup from '@primevue/themes/aura/inputgroup';
import inputnumber from '@primevue/themes/aura/inputnumber';
import inputtext from '@primevue/themes/aura/inputtext';
// import knob from '@primevue/themes/aura/knob';
// import listbox from '@primevue/themes/aura/listbox';
// import megamenu from '@primevue/themes/aura/megamenu';
// import menu from '@primevue/themes/aura/menu';
// import menubar from '@primevue/themes/aura/menubar';
// import message from '@primevue/themes/aura/message';
// import metergroup from '@primevue/themes/aura/metergroup';
// import multiselect from '@primevue/themes/aura/multiselect';
// import orderlist from '@primevue/themes/aura/orderlist';
// import organizationchart from '@primevue/themes/aura/organizationchart';
// import overlaybadge from '@primevue/themes/aura/overlaybadge';
// import paginator from '@primevue/themes/aura/paginator';
// import panel from '@primevue/themes/aura/panel';
// import panelmenu from '@primevue/themes/aura/panelmenu';
// import password from '@primevue/themes/aura/password';
// import picklist from '@primevue/themes/aura/picklist';
// import popover from '@primevue/themes/aura/popover';
// import progressbar from '@primevue/themes/aura/progressbar';
// import progressspinner from '@primevue/themes/aura/progressspinner';
// import radiobutton from '@primevue/themes/aura/radiobutton';
// import rating from '@primevue/themes/aura/rating';
// import ripple from '@primevue/themes/aura/ripple';
// import scrollpanel from '@primevue/themes/aura/scrollpanel';
// import select from '@primevue/themes/aura/select';
// import selectbutton from '@primevue/themes/aura/selectbutton';
// import skeleton from '@primevue/themes/aura/skeleton';
// import slider from '@primevue/themes/aura/slider';
// import speeddial from '@primevue/themes/aura/speeddial';
// import splitter from '@primevue/themes/aura/splitter';
// import stepper from '@primevue/themes/aura/stepper';
// import steps from '@primevue/themes/aura/steps';
// import tabmenu from '@primevue/themes/aura/tabmenu';
// import tabs from '@primevue/themes/aura/tabs';
// import tabview from '@primevue/themes/aura/tabview';
// import tag from '@primevue/themes/aura/tag';
// import terminal from '@primevue/themes/aura/terminal';
// import textarea from '@primevue/themes/aura/textarea';
// import tieredmenu from '@primevue/themes/aura/tieredmenu';
// import timeline from '@primevue/themes/aura/timeline';
// import toast from '@primevue/themes/aura/toast';
// import togglebutton from '@primevue/themes/aura/togglebutton';
// import toggleswitch from '@primevue/themes/aura/toggleswitch';
// import toolbar from '@primevue/themes/aura/toolbar';
// import tooltip from '@primevue/themes/aura/tooltip';
// import tree from '@primevue/themes/aura/tree';
// import treeselect from '@primevue/themes/aura/treeselect';
// import treetable from '@primevue/themes/aura/treetable';
// import virtualscroller from '@primevue/themes/aura/virtualscroller';

const index = {
  primitive: {
    borderRadius: {
      none: '0',
      xs: 'var(--border-radius-small)', // Replaced '2px'
      sm: 'var(--border-radius)', // Replaced '4px'
      md: 'var(--border-radius)', // Replaced '6px' → Using existing '--border-radius'
      lg: 'var(--border-radius-big)', // Replaced '8px'
      xl: 'var(--border-radius-big)', // Replaced '12px' → Using '--border-radius-big'
    },
    emerald: {
      50: '#ecfdf5',
      100: '#d1fae5',
      200: '#a7f3d0',
      300: '#6ee7b7',
      400: '#34d399',
      500: '#10b981',
      600: '#059669',
      700: '#047857',
      800: '#065f46',
      900: '#064e3b',
      950: '#022c22',
    },
    green: {
      50: '#f0fdf4',
      100: '#dcfce7',
      200: '#bbf7d0',
      300: '#86efac',
      400: '#4ade80',
      500: '#22c55e',
      600: '#16a34a',
      700: '#15803d',
      800: '#166534',
      900: '#14532d',
      950: '#052e16',
    },
    lime: {
      50: '#f7fee7',
      100: '#ecfccb',
      200: '#d9f99d',
      300: '#bef264',
      400: '#a3e635',
      500: '#84cc16',
      600: '#65a30d',
      700: '#4d7c0f',
      800: '#3f6212',
      900: '#365314',
      950: '#1a2e05',
    },
    red: {
      50: '#fef2f2',
      100: '#fee2e2',
      200: '#fecaca',
      300: '#fca5a5',
      400: '#f87171',
      500: '#ef4444',
      600: '#dc2626',
      700: '#b91c1c',
      800: '#991b1b',
      900: '#7f1d1d',
      950: '#450a0a',
    },
    orange: {
      50: '#fff7ed',
      100: '#ffedd5',
      200: '#fed7aa',
      300: '#fdba74',
      400: '#fb923c',
      500: '#f97316',
      600: '#ea580c',
      700: '#c2410c',
      800: '#9a3412',
      900: '#7c2d12',
      950: '#431407',
    },
    amber: {
      50: '#fffbeb',
      100: '#fef3c7',
      200: '#fde68a',
      300: '#fcd34d',
      400: '#fbbf24',
      500: '#f59e0b',
      600: '#d97706',
      700: '#b45309',
      800: '#92400e',
      900: '#78350f',
      950: '#451a03',
    },
    yellow: {
      50: '#fefce8',
      100: '#fef9c3',
      200: '#fef08a',
      300: '#fde047',
      400: '#facc15',
      500: '#eab308',
      600: '#ca8a04',
      700: '#a16207',
      800: '#854d0e',
      900: '#713f12',
      950: '#422006',
    },
    teal: {
      50: '#f0fdfa',
      100: '#ccfbf1',
      200: '#99f6e4',
      300: '#5eead4',
      400: '#2dd4bf',
      500: '#14b8a6',
      600: '#0d9488',
      700: '#0f766e',
      800: '#115e59',
      900: '#134e4a',
      950: '#042f2e',
    },
    cyan: {
      50: '#ecfeff',
      100: '#cffafe',
      200: '#a5f3fc',
      300: '#67e8f9',
      400: '#22d3ee',
      500: '#06b6d4',
      600: '#0891b2',
      700: '#0e7490',
      800: '#155e75',
      900: '#164e63',
      950: '#083344',
    },
    sky: {
      50: '#f0f9ff',
      100: '#e0f2fe',
      200: '#bae6fd',
      300: '#7dd3fc',
      400: '#38bdf8',
      500: '#0ea5e9',
      600: '#0284c7',
      700: '#0369a1',
      800: '#075985',
      900: '#0c4a6e',
      950: '#082f49',
    },
    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
      950: '#172554',
    },
    indigo: {
      50: '#eef2ff',
      100: '#e0e7ff',
      200: '#c7d2fe',
      300: '#a5b4fc',
      400: '#818cf8',
      500: '#6366f1',
      600: '#4f46e5',
      700: '#4338ca',
      800: '#3730a3',
      900: '#312e81',
      950: '#1e1b4b',
    },
    violet: {
      50: '#f5f3ff',
      100: '#ede9fe',
      200: '#ddd6fe',
      300: '#c4b5fd',
      400: '#a78bfa',
      500: '#8b5cf6',
      600: '#7c3aed',
      700: '#6d28d9',
      800: '#5b21b6',
      900: '#4c1d95',
      950: '#2e1065',
    },
    purple: {
      50: '#faf5ff',
      100: '#f3e8ff',
      200: '#e9d5ff',
      300: '#d8b4fe',
      400: '#c084fc',
      500: '#a855f7',
      600: '#9333ea',
      700: '#7e22ce',
      800: '#6b21a8',
      900: '#581c87',
      950: '#3b0764',
    },
    fuchsia: {
      50: '#fdf4ff',
      100: '#fae8ff',
      200: '#f5d0fe',
      300: '#f0abfc',
      400: '#e879f9',
      500: '#d946ef',
      600: '#c026d3',
      700: '#a21caf',
      800: '#86198f',
      900: '#701a75',
      950: '#4a044e',
    },
    pink: {
      50: '#fdf2f8',
      100: '#fce7f3',
      200: '#fbcfe8',
      300: '#f9a8d4',
      400: '#f472b6',
      500: '#ec4899',
      600: '#db2777',
      700: '#be185d',
      800: '#9d174d',
      900: '#831843',
      950: '#500724',
    },
    rose: {
      50: '#fff1f2',
      100: '#ffe4e6',
      200: '#fecdd3',
      300: '#fda4af',
      400: '#fb7185',
      500: '#f43f5e',
      600: '#e11d48',
      700: '#be123c',
      800: '#9f1239',
      900: '#881337',
      950: '#4c0519',
    },
    slate: {
      50: 'var(--color-shade-8)',
      100: 'var(--color-shade-7)',
      200: 'var(--color-shade-6)',
      300: 'var(--color-shade-5)',
      400: 'var(--color-shade-4)',
      500: 'var(--color-shade-3)',
      600: 'var(--color-shade-2)',
      700: 'var(--color-shade-1)',
      800: 'var(--color-shade-0)',
      900: 'var(--color-shade-0)',
      950: 'var(--color-shade-0)',
    },
    gray: {
      50: 'var(--color-shade-8)',
      100: 'var(--color-shade-7)',
      200: 'var(--color-shade-6)',
      300: 'var(--color-shade-5)',
      400: 'var(--color-shade-4)',
      500: 'var(--color-shade-3)',
      600: 'var(--color-shade-2)',
      700: 'var(--color-shade-1)',
      800: 'var(--color-shade-0)',
      900: 'var(--color-shade-0)',
      950: 'var(--color-shade-0)',
    },
    zinc: {
      50: '#fafafa',
      100: '#f4f4f5',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b',
      950: '#09090b',
    },
    neutral: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#e5e5e5',
      300: '#d4d4d4',
      400: '#a3a3a3',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262626',
      900: '#171717',
      950: '#0a0a0a',
    },
    stone: {
      50: '#fafaf9',
      100: '#f5f5f4',
      200: '#e7e5e4',
      300: '#d6d3d1',
      400: '#a8a29e',
      500: '#78716c',
      600: '#57534e',
      700: '#44403c',
      800: '#292524',
      900: '#1c1917',
      950: '#0c0a09',
    },
  },
  semantic: {
    transitionDuration: '0.2s',
    focusRing: {
      width: '1px',
      style: 'solid',
      color: 'var(--color-primary)',
      offset: '2px',
      shadow: 'none',
    },
    disabledOpacity: '0.6',
    iconSize: '1.2rem',
    anchorGutter: '2px',
    primary: {
      50: 'var(--color-primary)',
      100: 'var(--color-primary)',
      200: 'var(--color-primary)',
      300: 'var(--color-primary)',
      400: 'var(--color-primary)',
      500: 'var(--color-primary)',
      600: 'var(--color-primary-hover)',
      700: 'var(--color-primary-active)',
      800: 'var(--color-primary-active)',
      900: 'var(--color-primary-active)',
      950: 'var(--color-primary-active)',
    },
    formField: {
      paddingX: 'var(--line-xs)',
      paddingY: 'var(--line-xs)',
      borderRadius: '{border.radius.md}',
      lineHeight: 'var(--line-s)',
      focusRing: {
        width: '0',
        style: 'none',
        color: 'transparent',
        offset: '0',
        shadow: 'none',
      },
      transitionDuration: '{transition.duration}',
    },
    list: {
      padding: '0.25rem 0.25rem',
      gap: '2px',
      header: {
        padding: '0.5rem 1rem 0.25rem 1rem',
      },
      option: {
        padding: '0.5rem 0.75rem',
        borderRadius: '{border.radius.sm}',
      },
      optionGroup: {
        padding: '0.5rem 0.75rem',
        fontWeight: '600',
      },
    },
    content: {
      borderRadius: '{border.radius.md}',
    },
    mask: {
      transitionDuration: '0.15s',
    },
    navigation: {
      list: {
        padding: '0.25rem 0.25rem',
        gap: '2px',
      },
      item: {
        padding: '0.5rem 0.75rem',
        borderRadius: '{border.radius.sm}',
        gap: '0.5rem',
      },
      submenuLabel: {
        padding: '0.5rem 0.75rem',
        fontWeight: '600',
      },
      submenuIcon: {
        size: '0.875rem',
      },
    },
    overlay: {
      select: {
        borderRadius: '{border.radius.md}',
        shadow: 'var(--box-shadow-normal)',
      },
      popover: {
        borderRadius: '{border.radius.md}',
        padding: '0.75rem',
        shadow: 'var(--box-shadow-normal)',
      },
      modal: {
        borderRadius: '{border.radius.xl}',
        padding: '1.25rem',
        shadow: 'var(--box-shadow-hover)',
      },
      navigation: {
        shadow: 'var(--box-shadow-normal)',
      },
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          50: '{slate.50}',
          100: '{slate.100}',
          200: '{slate.200}',
          300: '{slate.300}',
          400: '{slate.400}',
          500: '{slate.500}',
          600: '{slate.600}',
          700: '{slate.700}',
          800: '{slate.800}',
          900: '{slate.900}',
          950: '{slate.950}',
        },
        primary: {
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.600}',
          activeColor: '{primary.700}',
        },
        highlight: {
          background: '{primary.50}',
          focusBackground: '{primary.100}',
          color: '{primary.700}',
          focusColor: '{primary.800}',
        },
        mask: {
          background: 'rgba(0,0,0,0.4)',
          color: '{surface.200}',
        },
        formField: {
          background: '{surface.0}',
          disabledBackground: '{surface.200}',
          filledBackground: '{surface.50}',
          filledFocusBackground: '{surface.50}',
          borderColor: '{surface.300}',
          hoverBorderColor: '{surface.400}',
          focusBorderColor: '{primary.color}',
          invalidBorderColor: '{red.400}',
          color: '{surface.700}',
          disabledColor: '{surface.500}',
          placeholderColor: '{surface.500}',
          floatLabelColor: '{surface.500}',
          floatLabelFocusColor: '{surface.500}',
          floatLabelInvalidColor: '{red.400}',
          iconColor: '{surface.400}',
          shadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05)',
        },
        text: {
          color: '{surface.700}',
          hoverColor: '{surface.800}',
          mutedColor: '{surface.500}',
          hoverMutedColor: '{surface.600}',
        },
        content: {
          background: '{surface.0}',
          hoverBackground: '{surface.100}',
          borderColor: '{surface.200}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        overlay: {
          select: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          popover: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          modal: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
        },
        list: {
          option: {
            focusBackground: '{surface.100}',
            selectedBackground: '{highlight.background}',
            selectedFocusBackground: '{highlight.focus.background}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            selectedColor: '{highlight.color}',
            selectedFocusColor: '{highlight.focus.color}',
            icon: {
              color: '{surface.400}',
              focusColor: '{surface.500}',
            },
          },
          optionGroup: {
            background: 'transparent',
            color: '{text.muted.color}',
          },
        },
        navigation: {
          item: {
            focusBackground: '{surface.100}',
            activeBackground: '{surface.100}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            activeColor: '{text.hover.color}',
            icon: {
              color: '{surface.400}',
              focusColor: '{surface.500}',
              activeColor: '{surface.500}',
            },
          },
          submenuLabel: {
            background: 'transparent',
            color: '{text.muted.color}',
          },
          submenuIcon: {
            color: '{surface.400}',
            focusColor: '{surface.500}',
            activeColor: '{surface.500}',
          },
        },
      },
      dark: {
        surface: {
          0: '#ffffff',
          50: '{zinc.50}',
          100: '{zinc.100}',
          200: '{zinc.200}',
          300: '{zinc.300}',
          400: '{zinc.400}',
          500: '{zinc.500}',
          600: '{zinc.600}',
          700: '{zinc.700}',
          800: '{zinc.800}',
          900: '{zinc.900}',
          950: '{zinc.950}',
        },
        primary: {
          color: '{primary.400}',
          contrastColor: '{surface.900}',
          hoverColor: '{primary.300}',
          activeColor: '{primary.200}',
        },
        highlight: {
          background: 'color-mix(in srgb, {primary.400}, transparent 84%)',
          focusBackground: 'color-mix(in srgb, {primary.400}, transparent 76%)',
          color: 'rgba(255,255,255,.87)',
          focusColor: 'rgba(255,255,255,.87)',
        },
        mask: {
          background: 'rgba(0,0,0,0.6)',
          color: '{surface.200}',
        },
        formField: {
          background: '{surface.950}',
          disabledBackground: '{surface.700}',
          filledBackground: '{surface.800}',
          filledFocusBackground: '{surface.800}',
          borderColor: '{surface.700}',
          hoverBorderColor: '{surface.600}',
          focusBorderColor: '{primary.color}',
          invalidBorderColor: '{red.300}',
          color: '{surface.0}',
          disabledColor: '{surface.400}',
          placeholderColor: '{surface.400}',
          floatLabelColor: '{surface.400}',
          floatLabelFocusColor: '{surface.400}',
          floatLabelInvalidColor: '{red.300}',
          iconColor: '{surface.400}',
          shadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05)',
        },
        text: {
          color: '{surface.0}',
          hoverColor: '{surface.0}',
          mutedColor: '{surface.400}',
          hoverMutedColor: '{surface.300}',
        },
        content: {
          background: '{surface.900}',
          hoverBackground: '{surface.800}',
          borderColor: '{surface.700}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        overlay: {
          select: {
            background: '{surface.900}',
            borderColor: '{surface.700}',
            color: '{text.color}',
          },
          popover: {
            background: '{surface.900}',
            borderColor: '{surface.700}',
            color: '{text.color}',
          },
          modal: {
            background: '{surface.900}',
            borderColor: '{surface.700}',
            color: '{text.color}',
          },
        },
        list: {
          option: {
            focusBackground: '{surface.800}',
            selectedBackground: '{highlight.background}',
            selectedFocusBackground: '{highlight.focus.background}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            selectedColor: '{highlight.color}',
            selectedFocusColor: '{highlight.focus.color}',
            icon: {
              color: '{surface.500}',
              focusColor: '{surface.400}',
            },
          },
          optionGroup: {
            background: 'transparent',
            color: '{text.muted.color}',
          },
        },
        navigation: {
          item: {
            focusBackground: '{surface.800}',
            activeBackground: '{surface.800}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            activeColor: '{text.hover.color}',
            icon: {
              color: '{surface.500}',
              focusColor: '{surface.400}',
              activeColor: '{surface.400}',
            },
          },
          submenuLabel: {
            background: 'transparent',
            color: '{text.muted.color}',
          },
          submenuIcon: {
            color: '{surface.500}',
            focusColor: '{surface.400}',
            activeColor: '{surface.400}',
          },
        },
      },
    },
  },
  components: {
    accordion,
    // autocomplete,
    // avatar,
    // badge,
    // blockui,
    // breadcrumb,
    // button,
    datepicker,
    // card,
    // carousel,
    // cascadeselect,
    // checkbox,
    // chip,
    // colorpicker,
    // confirmdialog,
    // confirmpopup,
    // contextmenu,
    // dataview,
    // datatable,
    // dialog,
    // divider,
    // dock,
    // drawer,
    // editor,
    // fieldset,
    // fileupload,
    // floatlabel,
    // galleria,
    // iconfield,
    // image,
    // inlinemessage,
    // inplace,
    // inputchips,
    // inputgroup,
    inputnumber,
    inputtext,
    // knob,
    // listbox,
    // megamenu,
    // menu,
    // menubar,
    // message,
    // metergroup,
    // multiselect,
    // orderlist,
    // organizationchart,
    // overlaybadge,
    // popover,
    // paginator,
    // password,
    // panel,
    // panelmenu,
    // picklist,
    // progressbar,
    // progressspinner,
    // radiobutton,
    // rating,
    // scrollpanel,
    // select,
    // selectbutton,
    // skeleton,
    // slider,
    // speeddial,
    // splitter,
    // stepper,
    // steps,
    // tabmenu,
    // tabs,
    // tabview,
    // textarea,
    // tieredmenu,
    // tag,
    // terminal,
    // timeline,
    // togglebutton,
    // toggleswitch,
    // tree,
    // treeselect,
    // treetable,
    // toast,
    // toolbar,
    // virtualscroller,
  },
  directives: {
    // tooltip,
    // ripple,
  },
};

// eslint-disable-next-line no-restricted-exports
export { index as default };
// # sourceMappingURL=index.mjs.map

import gql from 'graphql-tag';

export const NOTIFICATION_FIELDS = gql`
  fragment notificationFields on Notification {
    id
    module
    notificationType
    occurred_at
    payload
    processedPayload
    accountNotifications {
      edges {
        node {
          id
          read
          account {
            id
            email
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const COMMODITY_VOLUMES_YEAR_FIELDS = gql`
  fragment commodityVolumesYearFields on CommodityVolumesYear {
    id
    year
    savingLimit
    totalExpected
    months {
      collection {
        ...commodityVolumesMonthFields
      }
    }
  }
`;

export const COMMODITY_VOLUMES_MONTH_FIELDS = gql`
  fragment commodityVolumesMonthFields on CommodityVolumesMonth {
    id
    totalProduced
    sold
    totalSold
    open
    toBeProducedPerMonth
    expected
    yearNumber
    month
    produced
  }
`;

export const ACCOUNT_NAME_FIELDS = gql`
  fragment accountNameFields on Account {
    firstName
    middleName
    lastName
  }
`;

export const INDICATION_OF_INTEREST_OFFER_FIELDS = gql`
  ${ACCOUNT_NAME_FIELDS}
  fragment indicationOfInterestOfferFields on Offer {
    answeredAt
    createdAt
    createdBy {
      firstName
      middleName
      lastName
    }
    id
    indicationOfInterest {
      id
    }
    isAccepted
    price
    totalPrice
  }
`;

export const INDICATION_OF_INTEREST_FIELDS = gql`
  fragment indicationOfInterestFields on IndicationOfInterest {
    id
    type
    currencyCode
    expiresAt
    clientCompany {
      legalName
    }
    volume
    status
    createdAt
    offers {
      collection {
        answeredAt
        createdAt
        createdBy {
          firstName
          middleName
          lastName
        }
        id
        indicationOfInterest {
          id
        }
        isAccepted
        price
        totalPrice
      }
    }
    createdBy {
      firstName
      middleName
      lastName
    }
    commodity {
      id
      name
    }
  }
`;

export const ENERGY_EXCHANGE_ORDER_FIELDS = gql`
  fragment energyExchangeOrderFields on Order {
    createdAt
    deliveryDate
    id
    paymentDate
    priceId
    price
    status
    term
    totalAmount
    type
    updatedAt
    volume
    number
    isPaid
    executionType
    expiresAt
    createdBy {
      firstName
      lastName
      middleName
      clientCompany {
        id
        legalName
      }
    }
  }
`;

export const ENERGY_EXCHANGE_ORDER_FIELDS_WITH_ROLL_AND_DEC = gql`
  ${ENERGY_EXCHANGE_ORDER_FIELDS}
  fragment energyExchangeOrderFieldsWithRollAndDec on Order {
    ...energyExchangeOrderFields
    roll
    dec
  }
`;
